import Button from 'src/components/ui/Button'
import { Image } from 'src/components/ui/Image'
import SidebarService from 'src/components/ui/SidebarService/SidebarService'

import '../styles.scss'

const ValeTroca = () => {
  return (
    <>
      <section className="section-services exchange-voucher_container w-full">
        <div className="av-container">
          <div className="av-row section-services__flex">
            <div className="av-col-xs-24 av-col-md-7 section-services__column">
              {/* <!-SideBar Vale Troca-> */}
              <SidebarService />
            </div>

            <div className="av-col-xs-24 av-col-md-17">
              {/* <!- Vale Troca Products-> */}
              <div className="services-content">
                <div className="section__top align-center">
                  <h1 className="text-blue italic text-2xl font-bold text-center">
                    VALE TROCA
                  </h1>
                  <div className="flex items-center flex-col">
                    <Button className="bg-blue justify-center text-white text-xs my-0 m-auto flex items-center h-[30px] max-w-[234px] w-full hover:bg-darkBlue transition-all rounded-sm">
                      <a
                        className="consult-button text-xs font-bold uppercase"
                        href="https://wwws-usa1.givex.com/Merchant_pages/14697/"
                        target="_blank"
                        onClick={() =>
                          window.open(
                            'https://wwws-usa1.givex.com/Merchant_pages/14697/',
                            '',
                            'Location =no,status=no, scrollbars=no, width=270, height=440, resizable=no,top=0, left=30,scrollbars=no, directories=no,titlebar=no, menubar=no, maximized=no'
                          )
                        }
                        rel="noreferrer"
                      >
                        CONSULTAR SALDO DISPONÍVEL
                      </a>
                    </Button>
                    <p className="text-xs !text-shadeOfGray mt-2">
                      Aqui você pode consultar seu saldo do cartão.
                    </p>
                  </div>
                  <p className="text-left text-sm text-gray mt-4">
                    {' '}
                    Nosso vale troca é Omni! Mais facilidade para você que
                    realiza a troca pelo site ou em loja física e pode utilizar
                    o crédito em qualquer ponto Decathlon Brasil.
                  </p>
                  <p className="text-left text-sm text-gr mt-4">
                    Ele tem validade de até 30 dias e para clientes cadastrados
                    no Clube Decathlon o prazo é de 180 dias. Pode ser usado em
                    qualquer loja física no Brasil ou pelo site em sua
                    totalidade, parcialmente ou mesmo em conjunto com outras
                    formas de pagamento. Em uma compra que o valor total exceda
                    o crédito que foi disponibilizado, ele terá todo o seu saldo
                    consumido e o valor complementar poderá ser quitado com as
                    outras formas de pagamento disponíveis. Caso não seja
                    utilizado até o prazo descrito, o vale troca é
                    automaticamente cancelado, não há como utilizar o saldo que
                    havia pendente e não haverá, em nenhuma hipótese, a
                    restituição do valor.
                  </p>
                  <p className="text-left text-sm text-gr mt-4">
                    O vale troca é de uso do portador e não nominal portanto a
                    Decathlon não se responsabiliza por perda, roubo ou perda
                    que resulte na sua inutilização.
                  </p>
                  <p className="text-left text-sm text-gr font-black mt-2">
                    Vale troca pelo e-commerce
                  </p>
                  <p className="text-left text-sm text-gr mt-4">
                    Após realizada a solicitação de troca pelo Troque Fácil, o
                    envio da peça e conferência do produto no nosso Centro de
                    Distribuição, você receberá um e-mail de confirmação do
                    processo com o número de seu vale troca em até 48hs úteis.
                    Caso receba seu vale troca porém se arrependa, poderá
                    solicitar o cancelamento pela nossa Central de Atendimento
                    em até 7 dias da data do envio e a restituição será feita
                    pela mesma forma de pagamento do pedido original. O
                    vale-troca só poderá ser utilizado para compra de produtos
                    vendidos e entregues pela Decathlon.
                  </p>
                </div>
                <div className="hro">
                  <div className="av-row">
                    <div className="av-col-xs-24">
                      <h2 className="text-gray italic text-lg font-bold text-left">
                        COMO UTILIZA-LO?
                      </h2>
                      <p className="text-left text-sm text-gr font-black">
                        Site
                      </p>
                      <p className="text-left text-sm text-gr mt-4">
                        A numeração do vale-troca só será necessária no carrinho
                        de compras somente no momento do pagamento. Após
                        realizar o login no site ou o preenchimento de todos os
                        dados pessoais e do local de entrega ou retirada do
                        pedido. Veja o local a ser aplicado abaixo.
                      </p>

                      <Image
                        className="p-10"
                        src="https://decathlonproqa.vtexassets.com/arquivos/vale.png"
                        alt="vale"
                        width={526}
                        height={266}
                        loading="lazy"
                      />
                      <p className="text-left text-sm text-gr font-black">
                        Loja Física
                      </p>
                      <p className="text-left text-sm text-gr mt-4">
                        Basta comparecer a qualquer uma das lojas Decathlon no
                        Brasil, realizar sua compra e no momento do pagamento
                        apresentar o vale troca, seja ele impresso ou de forma
                        virtual.
                      </p>
                      <p className="text-left text-sm text-gr font-black mt-2">
                        Aplicativo
                      </p>
                      <p className="text-left text-sm text-gr mt-4">
                        No momento essa forma de pagamento não está disponível.
                        Em breve!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ValeTroca
